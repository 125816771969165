import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  theme,
  Button,
  HStack,
  Input,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.socket = io('https://ai.erudit.ai', {
      transports: ['websocket']
    });
    this.state = {
      messages: [],
      message: '',
      sender: null
    };
  }

  componentDidMount() {
    let udid = localStorage.getItem('udid');
    if (!udid) {
      udid = uuidv4();
      localStorage.setItem('udid', udid);
    }
    this.setState({sender: udid});

    this.socket.on('connect', () => {
      console.log('Connection successful');
    });

    this.socket.on('connect_error', (error) => {
      console.log('Connection error: ', error.message);
    });

    this.socket.on('history', (data) => {
      this.setState({messages: data});
    })

    this.socket.on('chat_response', (data) => {
      this.setState({messages: [...this.state.messages, data]});
    });
    this.socket.emit('history', {sender: udid})
  }

  render() {
    return (
      <ChakraProvider theme={theme}>
        <Box borderWidth="2px" borderRadius="lg" p="4">
          <VStack spacing="4" align="stretch" h="100%">
            <Box bg="gray.100" borderRadius="lg" p="4" overflowY="scroll">
              {this.state.messages.map((message, index) => (
                <Flex key={index} width="100%" direction="row" justify="flex-start">
                  {message.sender === this.state.sender && <Spacer />}
                  <Box maxWidth="50%" borderRadius="md" bg={message.sender ===  this.state.sender ? "blue.500" : "green.500"} color="white" p="2" mb="2">
                    {message.text}
                  </Box>
                  {message.sender === "erudit" && <Spacer />}
                </Flex>
              ))}
            </Box>
            <HStack>
              <Input
                variant="filled"
                placeholder="Type a message"
                value={this.state.message}
                onChange={(e) => this.setState({message: e.target.value})}
              />
              <Button colorScheme="blue" onClick={(event) => {
                let message = {text: this.state.message, sender: this.state.sender}
                this.socket.emit('chat', message);
                this.setState({messages: [...this.state.messages, message], message: ''});
              }} disabled={!this.state.message}>
                Send
              </Button>
            </HStack>
          </VStack>
        </Box>
      </ChakraProvider>
    );
  }
}

export default App;
